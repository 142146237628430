.application {
  padding: 0px 9.375em;
}
.application_title {
  width: 40%;
  margin-top: 100px;
  text-align: start;
  font-size: 33application_contpx;
  font-weight: 600;
}
.app_cont {
  width: 50%;
  display: flex;
  justify-content: center;
}
.application_cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 430px) {
  .application {
    padding: 0em 0.8em;
  }
  .application_title {
    width: 100%;
  }
  .app_cont {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .application {
    padding: 0px 1.5625em;
  }
}

@media only screen and (min-width: 1920px) {
  .application {
    padding: 0px 24.375em;
  }
}
@media only screen and (min-width: 2048px) {
  .application {
    padding: 0px 35.575em;
  }
}
