.privacy-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

.privacy-content {
  position: relative;
  width: 70%;
  height: 100%;
  background: white;
  overflow: scroll;
  top: 20px;
  bottom: 20px;
  padding: 10px 20px;
  border-radius: 10px;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}

.closeIcon:hover {
  color: darkred;
}
.info_cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info {
  width: 50%;
  background-color: #f7f7f7;
  border: 1px solid #d2d2d2;
  text-align: center;
}
.info_desc {
  color: #696969;
}
.link {
  color: #7671e8;
}
.info_title {
  margin-left: 30px;
}
