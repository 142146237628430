.footer {
  background-color: #112437;
  margin: 0;
  padding:0em 9.375em;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.footer_section {
  display: flex;
  justify-content: space-between;
  width: 40%;
  flex-wrap: wrap;
}
.privacy {
  font-size: 12px;
  color: #e1e8eb;
  cursor: pointer;
}
.footer_nav {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer_nav_itme {
  color: #abb2b9;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 1em;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.3s ease;
}
.footer_nav_itme:first-child {
  border-right: #998e8e 1px solid;
}
.footer_nav_itme:last-child {
  border-left: #998e8e 1px solid;
}
.footer_nav_itme:hover {
  transition: 0.3s ease;
  opacity: 0.8;
}
body.modal-open {
  overflow: hidden;
}

@media screen and (max-width: 430px) {
  .footer {
    padding:0em 0.8em;
  }
  .footer_section {
    width: 100%;
    justify-content: center;
  }
  .footer_nav {
    width: 100%;
   padding-bottom: 1em;
  }
  .footer_nav_itme{
    padding: 0em 0.4em;
  }
}
@media screen and (max-width: 1200px) {
  .footer {
    padding: 0px 1.5625em;
  }
}
@media only screen and (min-width: 1920px) {
  .footer {
    padding: 0px 24.375em;
  }
}

@media only screen and (min-width: 2048px) {
  .footer {
    padding: 0px 35.575em;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 0em 1.8em;
  }
  .footer_section {
    width: 100%;
    justify-content: center;
  }
  .footer_nav {
    width: 100%;
  }
}