.form{
    width: 100%;
    height: 45em;
    border-radius: 0.1875em;
    background-color: #F7F6F6;
    position: relative;
    z-index: 1000;
    
}
.button{
    width: 100%;
    border: none;
    font-size: 1em;
    padding:  1em;
    font-weight: 500;
    color:#F7F7F7;
    border-radius: 3px;
    background-color:#559AFF;
  transition: 0.3s ease-in-out;

}
.button:hover{
    cursor: pointer;
    background-color: #3687ff;
  transition: 0.3s ease-in-out;

}