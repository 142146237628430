.reviews {
  background-color: #f5f5f5;
  padding: 2em 9.375em;
}
.reviews_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.review_text {
  padding: 30px;
  height: 50%;
}
.rw {
  border-radius: 5px;

  padding: 20px 0px;
  background-color: #fff;
}

.review_item {
  text-align: center;

  width: calc(50% - 50px);
  margin: 0px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reviews_title {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
}
.review_from {
  font-weight: 300;
  font-size: 16px;
  color: #222222;
}
.star {
  color: #ffd726;
  margin: 0px 2px;
}
.review_desc {
  font-size: 15px;
  font-weight: 400;
  color: #444444;
}

.review_date {
  font-size: 10px;
  color: #c1c1c1;
}
.author {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.author_name {
  font-size: 16px;
  width: 100%;
}
@media screen and (max-width: 430px) {

  .reviews {
    padding: 1em 0.8em;
   
  }
  .review_item {
    text-align: center;
    width: 100%; 
    margin: 0px auto; 
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
}
@media screen and (max-width: 1200px) {
  .reviews{
    padding: 0px 1.5625em;
  }
}
@media only screen and (min-width: 1920px) {
.reviews{
  padding: 0px 35.575em;

}
}

@media only screen and (min-width: 2048px) {
  .reviews{
    padding: 0px 35.575em;
  }
  }