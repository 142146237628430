.banner {
  position: relative;
  background-image: url("../../assets/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 0px 9.375em;

  padding-top: 100px;
  z-index: 9;
}

.banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  opacity: 0.7;
}

.banner_content {
  position: relative;
  z-index: 1;
  padding-top: 100px;
  display: flex;
  justify-content: start;
  width: 40%;
  flex-wrap: wrap;
}
.banner_title {
  width: 100%;
  font-size: 24px;
  font-weight: 900;
  color: #ff7255;
  text-align: start;
  margin: 0px;
}
.banner_subtitle {
  margin: 0;
  text-align: start;
  font-weight: 900;
  margin: 20px 0px;
  color: #222222;
}
.banner_description {
  color: #62ad25;
  font-size: 20;
  width: 100%;

  text-align: start;
  margin-bottom: 20px;
  font-weight: 900;
}

.banner_button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #ff7255;
  color: #fff;
  font-size: 16;
  width: 220px;
  border-radius: 5px;
  padding: 15px 0px;
  transition: 0.3s ease;

}
.banner_button:hover{
 background-color: #ea5234;
  transition: 0.3s ease;
}
@media screen and (max-width: 430px){
  .banner{
    padding: 0em 0.8em;

    height: 1400px;
    display: block;
    padding-top: 200px;
  }
  .banner_content{
    width: 80%;
    margin-bottom: 70px;
  }
 .banner_title{
 width: 120%;
 }
 .banner_subtitle{
  width: 120%;
 }
}
@media screen and (max-width: 1200px) {
 .banner{
  padding: 0px 1.5625em;
 } 

}

@media only screen and (min-width: 1920px) {
  .banner{
    padding: 0px 24.375em;

  }
}
@media only screen and (min-width: 2048px) {
.banner{
  padding: 0px 35.575em;

}
}